import Swiper from '../node_modules/swiper/swiper-bundle';

window.addEventListener('load', function() {
    initAnimation();
    initUserInfo();

    const swiper = new Swiper('.swiper', {
        effect: 'fade',
        loop: 'true',
        autoplay: true,
        allowTouchMove: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    // swiper.on('click', (swiper, event) => {
    //     if (event.pageX <= window.innerWidth / 2) {
    //         swiper.slidePrev();
    //         return;
    //     }
    //     swiper.slideNext();
    // });
});

function initAnimation() {
    let elements = document.getElementsByClassName('animation');
    elements = Array.prototype.slice.call(elements);
    elements.forEach(element => {
        element.style.transition = `all ${300 + Math.random() * 1000}ms`;
    });
    elements.forEach(elementAnimation);
    window.addEventListener('scroll', function() {
        elements.forEach(elementAnimation);
    });
}

function elementAnimation(element) {
    const clientHeight = document.documentElement.clientHeight;
    const { top } = element.getBoundingClientRect();
    let calssNames = element.className.split(' ');
    if (top < clientHeight - 50 && ~calssNames.indexOf('inplace')) {
        element.className = calssNames
            .filter(name => name != 'inplace')
            .join(' ');
    } else if (top >= clientHeight - 50 && !~calssNames.indexOf('inplace')) {
        element.className = element.className + ' inplace';
    }
}

function initUserInfo() {
    let user = window.localStorage.user;
    if (!user) return;
    try {
        user = JSON.parse(user);
    } catch (e) {
        console.log(e);
        return;
    }
    if (!user.token) return;
    document.querySelector('.login').innerHTML = [
        '<a class="btn" href="dashboard.html">控制台</a>',
    ].join('');
}
